import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import Vid from "../../components/video";
import Accordion from 'react-bootstrap/Accordion';

const SwiftVideo = () => {

    const info={
        video:true,
        title:'The Swift Video',
        link:'https://www.youtube.com/embed/mjUN4Lnup7k',
        description:'In 1967, intense flashes of gamma rays were first observed from space. These brilliant bursts of energy burned brighter than all other gamma-ray sources in the sky.',
        internalText:'In 1967, intense flashes of gamma rays were first observed from space. These brilliant bursts of energy burned brighter than all other gamma-ray sources in the sky. They come from far out in the Universe, and to shine so brightly from such great distances, gamma-ray bursts must be the largest known energy releases since the Big Bang. To catch these events before the fade into obscurity, NASA is now considering a new observatory, an agile, advance, and responsive spacecraft that lives up to its name, Swift." - This Quicktime video is seven and a half minute long.',
        transcript:[
          "In 1967, intense flashes of gamma rays were first observed from space. These brilliant bursts of energy burned brighter than all other gamma-ray sources in the sky. They come from far out in the Universe, and to shine so brightly from such great distances, gamma-ray bursts must be the largest known energy releases since the Big Bang. To catch these events before the fade into obscurity, NASA is now considering a new observatory, an agile, advance, and responsive spacecraft that lives up to its name, Swift.",
          "Gamma rays exist at the high-energy end of the electromagnetic spectrum. They're part of a continuum that stretches from the lowest energy radio waves, through the thermal infrared of warm objects, into the rainbow spectrum of visible light, to higher energies like ultraviolet and X-ray bands. Finally, the most energetic form of electromagnetic radiation, with over 100,000 times the energy of visible light, is know as gamma rays. Only the most violent or energetic conditions produce this form of energy. These events include the decay of radioactive atoms, the collision of high-speed particles, or the incandescence of material heated to millions of degrees.",
          "Observing these flashes can be difficult. Gamma rays pass right through optical lenses and mirrors, so most telescopes produce fuzzy images. Most current observatories locate these bursts within a vague area of the sky, a size many times larger than the full moon. Scientists needed a better way. A newer technique, called coded aperture imaging, produces a sharper picture with observation positions, accurate to within a few arc-minutes.",
          "The BeppoSAX observatory was the first to use this technique to observe these releases of energy. It could quickly and accurately locate a gamma-ray burst, and within a few hours point sensitive X-ray telescopes toward the event. Although the source had faded by a factor of thousands in those hours, BeppoSAX was able to discover a faint afterglow of X-rays. When optical telescopes looked at these sources they saw dim, red fading points of light. Astronomers learned they could study these bursts even after the first few seconds of gamma ray release had passed. Hours later, the afterglow is still bright enough for larger aperture telescopes to gather important data and determine the redshift distance.",
          "The findings are astonishing. One well observed burst originated over 9 billion light years from Earth. Its gamma rays had been traveling for three quarters the age of the Universe. Putting its size and power in perspective, had this gamma-ray burst occurred only a 1000 light years away, on our side f the galaxy, the flash would have burned ten times brighter than the noon time sun.",
          "Even with these recent discoveries, big questions remain. What natural events could produce such amazing power, violent eruptions that convert a star's worth of mass into gamma-ray energy? How do these powerful explosions impact their surroundings? And what can these energy releases tell us about the Universe and the laws of physics under extreme conditions.",
          "To solve these mysteries an international collaboration of scientists from the United States, the United Kingdom, and Italy propose the next step, a mission called Swift. This new observatory will rapidly redirect a number of improved instruments toward these bursts, helping to lift the veil of secrecy surrounding this phenomenon.",
          "The proposed spacecraft will contain three instruments. The Burst Alert Telescope, called BAT, is an advance coded aperture gamma-ray telescope. A sensitive X-ray telescope, the XRT, provides fine imaging and spectroscopic resolution. And finally, the observatory will carry the UVOT, an ultraviolet and optical telescope. With these instruments working together, Swift will help scientists gain a better understanding of the very nature of gamma-ray bursts.",
          "About once a day the BAT will detect a bursts in its wide field of view and quickly determine its position to within a fifteenth of a degree. Swift will instantly notify large aperture telescopes on Earth where to find their new target. At the same time, the spacecraft will quickly re-point itself so its X-ray telescope and UVOT can immediately observe the event. In less than one minute, Swift will have its narrow-field instruments trained directly on the new flash location.",
          "The first minutes of a gamma-ray burst are critical. They're bright enough in visible light and X-rays to quickly reveal their distance and location. Gas and dust in the region around this flash will leave distinctive fingerprints on the data. As the afterglow continues to fade over the following minutes, hours, and days, the sensitive instruments on Swift will periodically return to recent bursts and monitor their decline, even as the BAT finds new bursts on a daily basis.",
          "Swift instruments will advance astronomers understanding as to the exact cause of gamma-ray bursts. And two major theories are current under consideration. One model is a hypernova. At the end of its life cycle a massive star rapidly burns up its fuel and falls into itself. The death scream of the falling star is the birth cry of the black hole. The result is a spectacular burst of gamma rays.",
          "1Massive stars live short and brilliant lives so they don't have time to move very far from their birthplaces. Scientist believe hypernova bursts will be found close to star forming regions, in the company of many other bright young stars. A second gamma-ray burst model involves two neutron stars locked in tight orbit around each other. They slowly spiral inward until, in a brief violet episode, they merge to form a black hole. In the billion years it takes for their orbit to decay, the pair of neutron stars can wonder far from the original galaxy. If these produce the gamma-ray event, they will be found in deep intergalactic space.",
          "Today, astronomers are posed to learn a great deal more about these spectacular bursts of gamma rays. The Swift mission is the next step in uncovering the origin of these fascinating events. Ultimately, scientists will learn more about the laws of physics under extreme conditions and perhaps mankind's place in the Universe."
        ]
    }

  return (
    <Layout>
        <div style={{width:'100%'}}>
            <h1>{info.title}</h1>
            <Star />
            <Vid videoLink={info.link}/>
            <Accordion flush>
                <Accordion.Item eventKey={1} key={1}>
                        <Accordion.Header>Transcript</Accordion.Header>
                        <Accordion.Body>
                                {info.transcript ? 
                                    info.transcript.map( p => <p style={{textAlign:'start'}}>{p}</p>)
                                    : 
                                    null
                                }
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <p style={{textAlign:'start', margin:'1em 0'}}>{info.internalText}</p>
        </div>
    </Layout>
  )
}

export default SwiftVideo